import Link from "next/link";
import React from "react";
import { MdVerified } from "react-icons/md";
import { JobDto, JobOtherDto } from "../../lib/api/job";

import { usePageDispatch } from "../../lib/context/PageContext";
import CustomImage from "../common/CustomImage";
import CustomLink from "../common/CustomLink";

const FAVORITED_CLASS = "btn btn-sm btn-primary";
const NOT_FAVORITED_CLASS = "btn btn-sm btn-outline-primary";

interface Props {
  job: JobOtherDto | JobDto;
  forGiId: string;
}

const JobPreview = ({ job, forGiId }: Props) => {
  const setPage = usePageDispatch();

  const [preview, setPreview] = React.useState(job);
  const [hover, setHover] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(-1);

  if (!job) return;

  return (
    <div className="article-preview" style={{ padding: "1.5rem 0.5rem" }}>
      <div className="article-meta">
        <CustomLink href="/company/[id]" as={`/company/${encodeURIComponent(job?.author?.username) || 404}`}>
          <CustomImage src={preview?.author?.image || "/images/user.jpg"} alt="author's profile image" />
        </CustomLink>

        <div className="info">
          <CustomLink href="/company/[id]" as={`/company/${encodeURIComponent(job?.author?.username) || 404}`} className="author">
            <span onClick={() => setPage(0)}>{preview?.author?.companyname || "Unknown"}</span>
            {preview?.author?.verified && <MdVerified style={{ marginLeft: "0.2em" }} />}
          </CustomLink>
          <span className="date">
            {new Date(preview?.createdAt).toDateString()}
          </span>
        </div>
      </div>

      <CustomLink
        href="/jobs/[id]"
        as={`/jobs/${encodeURIComponent(preview?.postId)}`}
        className="preview-link"
      >
        <h1>{preview.title}</h1>
        <p>{preview.description}</p>
        <span>Read more...</span>
        <ul className="tag-list" style={{ maxWidth: "100%" }}>
          {preview.tagList.map((tag, index) => {
            return (
              <Link href={`/?tag=${tag}`} as={`/?tag=${tag}`} key={index}>
                <li
                  className="tag-default tag-pill tag-outline"
                  onClick={(e) => e.stopPropagation()}
                  onMouseOver={() => {
                    setHover(true);
                    setCurrentIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                    setCurrentIndex(-1);
                  }}
                  style={{
                    borderColor:
                      hover && currentIndex === index ? "#5cb85c" : "initial",
                  }}
                >
                  <span
                    style={{
                      color:
                        hover && currentIndex === index ? "#5cb85c" : "inherit",
                    }}
                    onClick={() => setPage(0)}
                  >
                    {tag}
                  </span>
                </li>
              </Link>
            );
          })}
        </ul>
        {job?.authorGiId === forGiId && <p style={{ color: "grey" }}>Number of applicants: {(job as JobDto).applied.length}</p>}
      </CustomLink>
    </div>
  );
};

export default JobPreview;
