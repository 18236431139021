import styled from "@emotion/styled";
import React from "react";


const BannerStyled = styled("img")`
  width:100%;
  height: auto;
`;

const imgUrl = '/images/Banner_GI.jpg'

const divStyle = {
  color: 'blue',
  backgroundImage: 'url(' + imgUrl + ')',
};


const Banner = () => (
  /*
  <div className="banner" style={divStyle}>
    <div className="container">
      <h1 className="logo-font">{APP_NAME.toLowerCase()}</h1>
      <p>Portal de prácticas</p>
    </div>
  </div>
  */
    <BannerStyled src= 'images/Banner_GI.jpg'></BannerStyled>

);

export default Banner;
