import styled from "@emotion/styled";
import Banner from "components/home/Banner";
import TabList from "components/home/TabList";
import Tags from "components/home/Tags";
import { useSession } from "next-auth/react";
import Head from "next/head";
import JobList from "../components/article/JobList";


const IndexPageContainer = styled("div")``;

const IndexPagePresenter = styled("div")`
  margin: 1.5rem auto 0;
  padding: 0 15px;
  

  @media (min-width: 544px) {
    max-width: 576px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 940px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const MainContent = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  
`;

const ContentContainer = styled("div")`
  width: 100%;
  @media (min-width: 768px) {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 75%;
    max-width: 75%;
  }
`;

const FeedToggle = styled("div")`
  margin-bottom: -1px;
  
`;

const SidebarContainer = styled("div")`
  @media (min-width: 768px) {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

const SidebarPresenter = styled("div")`
  padding: 5px 10px 10px;
  background: #f3f3f3;
  border-radius: 4px;
`;

const SidebarTitle = styled("p")`
  margin-top: 0;
  margin-bottom: 0.2rem;
  
`;

export default function IndexPage() {
  const { data: session, status } = useSession()
  return (
    <>
      <Head>
        <title>Geninquieta</title>
        <meta
          name="description"
          content="Geninquieta (GI) - Generación inquieta - Plataforma de prácticas para estudiantes en España"
        />
      </Head>
      <IndexPageContainer className="home-page">
        <Banner />
        <IndexPagePresenter>
          <MainContent>
            <ContentContainer>
              <FeedToggle>
                <TabList />
              </FeedToggle>
              <JobList forGiId={session?.giId} />
            </ContentContainer>
            <SidebarContainer>
              <SidebarPresenter>
                <SidebarTitle>Tags</SidebarTitle>
                <Tags />
              </SidebarPresenter>
            </SidebarContainer>
          </MainContent>
        </IndexPagePresenter>
      </IndexPageContainer>
    </>
  )
};
