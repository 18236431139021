import styled from "@emotion/styled";
import ErrorMessage from "components/common/ErrorMessage";
import LoadingSpinner from "components/common/LoadingSpinner";
import { usePageState } from "lib/context/PageContext";
import {
  usePageCountDispatch, usePageCountState
} from "lib/context/PageCountContext";
import { DEFAULT_LIMIT } from "lib/utils/constant";
import fetcher from "lib/utils/fetcher";
import { useRouter } from "next/router";
import { useMemo } from "react";
import useSWR from "swr";
import { JobOtherDto } from "../../lib/api/job";
import { ResponseData } from "../../lib/utils/HTTP";
import JobPreview from "./JobPreview";


const EmptyMessage = styled("div")`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 0;
`;

interface Props {
  forGiId: string;
}

const JobList = ({ forGiId }: Props) => {
  const page = usePageState();
  const pageCount = usePageCountState();
  const setPageCount = usePageCountDispatch();
  const lastIndex =
    pageCount > 480 ? Math.ceil(pageCount / 20) : Math.ceil(pageCount / 20) - 1;

  const router = useRouter();
  const { asPath, query } = router;
  const { tag } = query;

  const getFetchURL = () => {
    switch (true) {
      case !!tag:
        return `api/posts${asPath}&offset=${page * DEFAULT_LIMIT
          }`;
      default:
        return `api/posts?offset=${page * DEFAULT_LIMIT}`;
    }
  };

  /*
  let fetchURL = useMemo(() => getFetchURL(), [
    favorite,
    page,
    tag,
    isProfilePage,
  ]);
  */

  let fetchURL = useMemo(() => getFetchURL(), [tag]);

  const { data, error } = useSWR<ResponseData<JobOtherDto[]>>(fetchURL, fetcher);

  /* 
  const { articles, articlesCount } = data || {
    articles: [],
    articlesCount: 0,
  };
  */

  const { data: jobList } = data || {
    jobList: []
  };
  /*
    useEffect(() => {
      setPageCount(articlesCount);
    }, [articlesCount])
    */

  if (error) return <ErrorMessage message="Cannot load recent articles..." />;
  if (!data) return <LoadingSpinner />;


  if (jobList?.length === 0) {
    return <EmptyMessage>No hay empleos... de momento.</EmptyMessage>;
  }

  return (
    <>
      {jobList?.map((job) => (
        <JobPreview key={job?.postId} job={job} forGiId={forGiId} />
      ))}
      {/*
      <Maybe test={articlesCount && articlesCount > 20}>
        <Pagination
          total={pageCount}
          limit={20}
          pageCount={10}
          currentPage={page}
          lastIndex={lastIndex}
          fetchURL={fetchURL}
        />
      </Maybe>
      */}
    </>
  );
};

export default JobList;
