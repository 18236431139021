import React from "react";
import useSWR from "swr";
import { usePageDispatch } from "../../lib/context/PageContext";
import fetcher from "../../lib/utils/fetcher";
import { ResponseData } from "../../lib/utils/HTTP";
import CustomLink from "../common/CustomLink";
import ErrorMessage from "../common/ErrorMessage";
import LoadingSpinner from "../common/LoadingSpinner";

/* export async function getServerSideProps () {
  // `getStaticProps` is executed on the server side.
  const tags = await TagAPI.getAll();
  return {
    props: {
      tags: {
        'api/tags': tags
      }
    }
  }
} */

const Tags = () => {
  const setPage = usePageDispatch();
  const handleClick = React.useCallback(() => setPage(0), []);
  const { data, error } = useSWR(`api/tags`, fetcher);

  if (error) return <ErrorMessage message="Cannot load popular tags..." />;
  if (!data) return <LoadingSpinner />;

  const res: ResponseData<string[]> = data;
  if(res.error){
    console.log('ERROR')
  }
  const tags = res.data;
  console.log('tagss')
  return (
    <div className="tag-list">
      {tags?.map((tag: string) => (
        <CustomLink
          key={tag}
          href={`/?tag=${tag}`}
          as={`/?tag=${tag}`}
          className="tag-default tag-pill"
        >
          <span onClick={handleClick}>{tag}</span>
        </CustomLink>
      ))}
    </div>
  );
};


export default Tags;
